<template>
  <v-row>
    <v-col cols="2">
      <v-select
        v-model="editedItem.company_type"
        :items="$store.state.client_type"
        label="구분"
        item-text="label"
        clearable
        hide-details
      />
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="editedItem.name_only" label="회사명 (구분)">
        <template v-slot:prepend-inner="">
          <div style="display: grid">
            <span
              class="labelText"
              :class="$store.state.editedItem._id ? 'disable' : ''"
            >
              좌
            </span>

            <input
              v-model="editedItem.type_postion"
              :disabled="!editedItem.company_type"
              value="left"
              type="radio"
              class="radioCheckBox"
            />
          </div>
        </template>
        <template v-slot:append="">
          <div style="display: grid">
            <span
              class="labelText"
              :class="$store.state.editedItem._id ? 'disable' : ''"
            >
              우
            </span>
            <input
              :disabled="!editedItem.company_type"
              v-model="editedItem.type_postion"
              value="right"
              type="radio"
              class="v-checkbox"
            />
          </div>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="2">
      <v-text-field v-model="editedItem.zone" label="지점"></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="fullName" label="회사명" disabled></v-text-field>
    </v-col>
    <v-col cols="5">
      <v-autocomplete
        return-object
        v-model="editedItem.bill_client"
        label="계산서 발행처"
        :items="$store.state.useClientList"
        item-text="name_only"
        item-value="bill_client.name"
        @change="billChange"
      >
        <!-- <template v-slot:selection="{ item }">
          {{ item }}
        </template> -->
      </v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-text-field v-model="editedItem.officer" label="대표명"></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        v-model="editedItem.business_num"
        label="사업자번호"
        @input="(e) => (editedItem.business_num = this.getBusinessNumMask(e))"
        @keyup="
          $event.target.value = $event.target.value.replace(/[^-0-9]/g, '')
        "
        maxlength="12"
      ></v-text-field>
    </v-col>

    <v-col cols="6">
      <v-text-field v-model="editedItem.industry" label="업종"></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="editedItem.tel"
        label="전화번호"
        @input="(e) => (editedItem.tel = this.getPhoneMask(e))"
        @keyup="
          $event.target.value = $event.target.value.replace(/[^-0-9]/g, '')
        "
        maxlength="13"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        @input="(e) => (editedItem.fax = this.getPhoneMask(e))"
        v-model="editedItem.fax"
        label="팩스번호"
        maxlength="13"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="editedItem.email" label="이메일"></v-text-field>
    </v-col>
    <v-col cols="9">
      <v-text-field v-model="editedItem.address" label="주소"></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="editedItem.liaison"
        label="연락담당자"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
// import LabelSelect from "@/components/atoms/VSelect/LabelSelect";
import ClienTypeAcronyms from "@/assets/data/kind/ClienTypeAcronyms";
import { mapGetters } from "vuex";
export default {
  components: {
    // LabelSelect,
  },
  computed: {
    fullName() {
      const name = `${
        this.editedItem.type_postion === "left" && this.editedItem.company_type
          ? ClienTypeAcronyms[this.editedItem.company_type - 1].label
          : ""
      }${this.editedItem.name_only ? this.editedItem.name_only : ""}${
        this.editedItem.type_postion === "right" && this.editedItem.company_type
          ? ClienTypeAcronyms[this.editedItem.company_type - 1].label
          : ""
      }${
        this.editedItem.name_only && this.editedItem.zone
          ? `[${this.editedItem.zone}]`
          : ""
      }`;

      return name;
    },
    ...mapGetters({
      editedItem: "getEditedItem",
    }),
  },
  data() {
    return {
      ClienTypeAcronyms,
      viewTel: "",
      viewBusinessNum: "",
      type_postion: "",
    };
  },
  methods: {
    radioCheckChange(event) {
      for (let i = 0; i < this.type_postion.length; i++) {
        if (this.type_postion[i] !== event.target.value) {
          this.type_postion.splice(i, 1);
        }
      }
      if (event.target.checked)
        this.editedItem.type_postion = event.target.value;
    },
    billChange() {
      this.editedItem.business_num = this.editedItem.bill_client.business_num
        ? this.getBusinessNumMask(this.editedItem.bill_client.business_num)
        : null;
    },
  },
};
</script>
