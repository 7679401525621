<template>
  <v-row>
    <v-col cols="12">
      <v-text-field v-model="editedItem.name" label="이름"></v-text-field>
    </v-col>
    <!-- <v-col cols="12">
      <v-text-field v-model="editedItem.content" label="내용"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field v-model="editedItem.liaison" label="담당자"></v-text-field>
    </v-col> -->
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      editedItem: "getEditedItem",
    }),
  },
};
</script>
